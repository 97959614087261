.App {
  width: 1000px;
  max-width: 100vw;
  color: #ddd;

  justify-self: center;
}

.slick-dots li button:before, .slick-dots li.slick-active button:before {
  color: #fff;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #181818;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ddd;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #aaa;
}


.header {
  padding: 1rem 0;
  display: flex;

  justify-content: space-between;
  align-items: center;

  margin-bottom: 4rem;
}

.header__button {
  text-decoration: none;
  display: block;
  padding: 0.75rem 2.5rem;
  font-size: 1rem;
  border-radius: 0.5rem;
  border: 1px solid #ddd;
  color: #ddd;
  background-color: #0000;
  font-weight: 600;

  transition: 0.6s;
}

.header__button:hover {
  color: #181818;
  background-color: #ddd;
}

.project-image {
  width: 100%;
  max-width: 1000px;
}

.project__images-block {
  margin: 3rem 0;
}

.about-block {
  display: flex;
  font-size: 1.25rem;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  height: 10rem;
  margin-bottom: 4rem;
}

.about__text-content {
  width: 55%;
}

.contacts__list {
  list-style-type: none;
  font-weight: 600;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.link {
  text-decoration: none;
  color: #3ba352;
  font-weight: normal;
  transition: .6s;
}

.link:hover {
  color: #00ff37;
}

.projects-title {
  font-size: 1.75rem;
  text-align: center;
}

.project {
  margin-bottom: 4rem;
  border-top: 2px solid #ddd;
}

.project__title {
  font-size: 1.5rem;
}

.project__desc {
  font-size: 1.2rem;
  width: 80%;
}

.project__list {
  font-size: 1.2rem;
  padding-left: 2rem;
}

.project__list__item {
  margin: 0.5rem 0;
}

.project__refs {
  list-style-type: none;
  font-size: 1.2rem;
  height: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footer {
  border-top: 2px solid #ddd;
  padding: 5rem 0;
}

.footer__links {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
  padding-left: 0;
}

.footer__link {
  font-size: 1.3rem;
  display: flex;
  align-items: center;
}

.footer__icon {
  height: 1.5rem;
  margin-right: 0.5rem;
}

.footer__ref {
  color: #ddd
}

.button-top {
  position: absolute;
  right: 5rem;
  bottom: 5rem;

  background-color: #181818;
  border-radius: 3rem;
  padding: 0.6rem;
  border: 1px solid #ddd;
  position: fixed;

  transition: .6s;
}

.button-top:hover {
  background-color: #aaa;
  border: 1px solid #aaa;
}

.button-top img {
  width: 3rem;
}

@media screen and (max-width: 1096px) {
  .App {
    width: auto;
  }
  
  .header {
    padding: 1rem 3rem;

    margin-bottom: 4rem;
  }
  
  ::-webkit-scrollbar {
    width: 6px;
  }
  
  .header__button {
    font-size: 1.2rem;
  }
  
  .project__images-block {
    margin: 5rem 0;
  }
  
  .about-block {
    height: 13rem;
    padding: 0 3rem;
    margin-bottom: 5rem;
  }
  
  .project {
    padding: 0 3rem;
  }
  
  .footer {
    padding: 3rem 0;
  }
  
  .button-top {
    right: 3rem;
    bottom: 3rem;
  
    background-color: #0007;
    backdrop-filter: blur(10px);
  }
}

@media screen and (max-width: 800px) {
  .header {
    margin-bottom: 1rem;
  }

  .about-block {
    flex-direction: column;
    height: auto;
    margin-bottom: 1rem;
  }
  
  .about__text-content {
    width: 100%;
    text-align: center;
  }
  
  .contacts__list {
    height: 13rem;
  }
}

@media screen and (max-width: 500px) {
  .header {
    padding: 1rem 3rem;
    flex-direction: column;
    margin-bottom: 2rem;
  }
  
  .header__button {
    margin-top: 0.5rem;
    padding: 0.75rem 3rem;
    font-size: 1rem;
    border-radius: 0.5rem;
  }
  
  .project__images-block {
    margin: 3rem 0;
  }
  
  .about-block {
    margin-bottom: 2rem;
    padding: 0 0.5rem;
  }
  
  .contacts__list {
    padding-left: 0;
  }
  
  .projects-title {
    font-size: 1.5rem;
  }
  
  .project {
    margin-bottom: 3rem;
    padding: 0 1rem;
  }
  
  .project__title {
    font-size: 1.3rem;
  }
  
  .project__desc {
    width: 100%;
  }
  
  .project__list {
    padding-left: 1rem;
  }
  
  .project__list__item {
    margin: 0.75rem 0;
  }
  
  .project__refs {
    height: 5rem;
    display: flex;
    padding-left: 0;
  }
  
  .footer {
    padding: 0;
  }
  
  .footer__links {
    flex-direction: column;
    align-items: center;
  }
  
  .footer__link {
    margin: 1rem 0;
  }
  
  .footer__icon {
    height: 1.5rem;
    margin-right: 0.5rem;
  }
  
  .button-top {
    right: 1rem;
    bottom: 2rem;

    padding: 0.4rem;
  }
}